(function () {
    var use_webp = window.canUseWebP || null;
    var use_avif = window.canUseAVIF || null;
    function testWebP(callback) {
        var webP = new Image();
        webP.onload = webP.onerror = function () {
            callback(webP.height == 2);
        };
        webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
    }
    function testAVIF(callback) {
        var avif = new Image();
        avif.onload = avif.onerror = function () {
            callback(avif.height == 1);
        };
        avif.src = 'data:image/avif;base64,AAAAHGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZgAAAPBtZXRhAAAAAAAAAChoZGxyAAAAAAAAAABwaWN0AAAAAAAAAAAAAAAAbGliYXZpZgAAAAAOcGl0bQAAAAAAAQAAAB5pbG9jAAAAAEQAAAEAAQAAAAEAAAEUAAAAFQAAAChpaW5mAAAAAAABAAAAGmluZmUCAAAAAAEAAGF2MDFDb2xvcgAAAABoaXBycAAAAElpcGNvAAAAFGlzcGUAAAAAAAAAAQAAAAEAAAAOcGl4aQAAAAABCAAAAAxhdjFDgQAcAAAAABNjb2xybmNseAABAAEAAQAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAAB1tZGF0EgAKBxgADlgICAkyCB/xgAAghQm0'
    }
    function atStartup() {
        testWebP(function (canSupport) {
            use_webp = canSupport
            window.canUseWebP = use_webp
        });
        testAVIF(function (canSupport) {
            use_avif = canSupport
            window.canUseAVIF = use_avif
        });
    }
    registerListener('load', atStartup);

    setInterval(lazyLoad, 500);
    var lazy = [];

    function lazyLoad() {
        if (use_webp === null) {
            atStartup();
            return;
        }
        lazy = document.getElementsByClassName('coglazy');
        if (use_avif) {
            for (var i = 0; i < lazy.length; i++) {
                if (lazy[i].getAttribute('data-avif')) {
                    lazy[i].setAttribute('data-src', lazy[i].getAttribute('data-avif'));
                    lazy[i].removeAttribute('data-avif');
                }
            }
        } else if (use_webp) {
            for (var i = 0; i < lazy.length; i++) {
                if (lazy[i].getAttribute('data-webp')) {
                    lazy[i].setAttribute('data-src', lazy[i].getAttribute('data-webp'));
                    lazy[i].removeAttribute('data-webp');
                }
            }
        }
        var replaced_placeholder = false
        for (var i = 0; i < lazy.length; i++) {
            if (lazy[i].getAttribute('coglazyplaceholder')) {
                continue
            }
            if (lazy[i].getAttribute('data-src')) {
                lazy[i].src = lazy[i].getAttribute('data-src');
                lazy[i].setAttribute('coglazyplaceholder', '1')
            }
            replaced_placeholder = true
        }
        if (replaced_placeholder) {
            // Allow the preloaded image to load
            return;
        }
        for (var i = 0; i < lazy.length; i++) {
            if (lazy[i].getAttribute('coglazydone')) {
                continue
            }
            if (isInViewport(lazy[i])) {
                if (lazy[i].getAttribute('data-src')) {
                    var srcurl = lazy[i].getAttribute('data-src');
                    var filename = srcurl.split('/').pop();
                    var width = Math.floor(lazy[i].clientWidth * window.devicePixelRatio);
                    var height = Math.floor(lazy[i].clientHeight * window.devicePixelRatio);

                    lazy[i].src = srcurl.replace(filename, '') + width + 'x' + height + ':' + filename;
                    lazy[i].removeAttribute('data-src');
                }
                lazy[i].setAttribute('coglazydone', '1')
            }
        }
    }

    function isInViewport(el) {
        var rect = el.getBoundingClientRect();
        var screenheight = window.innerHeight || document.documentElement.clientHeight
        var screenwidth = window.innerWidth || document.documentElement.clientWidth
        // Increase height to pre-load on scroll
        screenheight += 200
        return (
            rect.bottom >= 0 &&
            rect.right >= 0 &&
            rect.top <= screenheight &&
            rect.left <= screenwidth
        );
    }

    function registerListener(event, func) {
        if (window.addEventListener) {
            window.addEventListener(event, func)
        } else {
            window.attachEvent('on' + event, func)
        }
    }
})();
